import flatpickr from "flatpickr";
import { Russian } from "flatpickr/dist/l10n/ru.js"

export default function () {
  window.addEventListener('DOMContentLoaded', () => {
    setTimeout(initCalendar, 500);
  });
}

function initCalendar() {
  const toggleBtn = document.querySelector('.filter__calendar-toggle');

  toggleBtn && toggleBtn.classList.remove('hidden');

  flatpickr("#datepicker", {
    locale: Russian,
    inline: true,
    dateFormat: 'd-m-Y',
    prevArrow: '',
    nextArrow: '',
    monthSelectorType: 'static',
    disable: [() => true],
    onDayCreate: function (dObj, dStr, fp, dayElem) {
      const list = getList(dayElem.dateObj);

      if (list) {
        dayElem.innerHTML += list;
        addItemEvents(dayElem);
      } else {
        dayElem.classList.add('empty');
      }
    }
  });
}

function addItemEvents(element) {
  const allBtn = element.querySelector('.calendar__all');
  const group = element.querySelector('.calendar__group');

  group.addEventListener('click', function (e) {
    e.stopPropagation();
  });

  allBtn.addEventListener('click', function (e) {
    e.preventDefault();

    group.classList.add('calendar__group--all');
  });
}

function getList(date) {
  let items = '';
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const year = date.getFullYear();
  const formattedDate = `${day}.${month}.${year}`;

  for (const item of document.querySelectorAll(`[data-calendar-date="${formattedDate}"]`)) {
    const author = item.getAttribute('data-calendar-author');
    const scene = item.getAttribute('data-calendar-scene');
    const tagParams = {
      name: item.getAttribute('data-tag-name'),
      text: item.getAttribute('data-tag-text'),
      link: item.getAttribute('data-tag-link'),
    }
    const params = {
      author: author ? author : '',
      scene: scene ? scene : '',
      title: item.getAttribute('data-calendar-title'),
      img: item.getAttribute('data-calendar-img'),
      daymonth: item.getAttribute('data-calendar-daymonth'),
      weekday: item.getAttribute('data-calendar-weekday'),
      time: item.getAttribute('data-calendar-time'),
      href: item.getAttribute('data-calendar-href'),
      tags: tagParams.text ? getLinkTags(tagParams) : '',
      genre: item.getAttribute('data-calendar-genre'),
      addinfo: item.getAttribute('data-calendar-addinfo') || '',
    }

    items += getLinkTemplate(params);
  }

  return getLinksGroupTemplate(items);
}

function getLinksGroupTemplate(items) {
  return items ? `
    <div class="calendar__group">
      ${items}
      <a href="#" class="calendar__all">еще </a>
    </div>
  ` : null;
}

function getLinkTemplate({ author, scene, title, img, daymonth, weekday, time, href, tags, genre, addinfo }) {
  console.log('tags', tags);
  genre = genre ? `<div class="calendar__pane-genre">${genre}</div>` : "";
  return `
    <div class="calendar__link">
      <a class="calendar__link-text" href="${href}" target="_blank">${title}</a>
      <div class="calendar__tags">${tags}</div>
      <div class="calendar__pane">
        <div class="calendar__pane-head">
          <div class="calendar__pane-head-text">${author}</div>
          <div class="calendar__pane-head-text">${scene}</div>
        </div>
        <div class="calendar__pane-title">${title}</div>
        ${genre}
        <div class="calendar__pane-tags">${tags}</div>
        <div class="calendar__pane-footer">
          ${addinfo && addinfo != '' ? '<div class="calendar__pane-addinfo">' + addinfo + '</div>' : ''}
          <div class="calendar__pane-date">
            ${daymonth}, ${weekday}
            <div class="calendar__pane-time">${time}</div>
          </div>
          <div class="calendar__pane-img">
            <img src="${img}" alt="">
          </div>
        </div>
      </div>
    </div>
  `;
}

function getLinkTags({ text, name, link }) {
  return link ?
    `<a href="${link}" class="status-${name} calendar__tag">${text}</a>`
    :
    `<div class="status-${name} calendar__tag">${text}</div>`;
}
